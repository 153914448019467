import React from 'react'

import { COLOR } from '@farewill/ui/tokens'

import JSONLD from 'components/JSONLD'
import { FUNERAL_PLAN_EMAIL, FUNERAL_PLAN_TELEPHONE_NUMBER } from 'config'
import DefaultLayout from 'layouts/DefaultLayout'
import FUNERAL_PLANS_JSONLD from 'lib/json-ld/funeral-plans'
import {
  funeralPlanBreadCrumb,
  getStructuredBreadCrumbs,
} from 'lib/json-ld/getStructuredBreadCrumbs'
import { PRODUCTS } from 'lib/products/constants'
import { FORMATTED_CREMATION_FUNERAL_PLAN_FEE } from 'views/cremation/shared/constants'

import Content from './Content'

const pageTitle = `Direct Cremation Funeral Plans for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE} - Arrange today with Farewill Funerals`
const pageDescription = `A simple prepaid funeral plan for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE}. No fuss and no hidden costs, just peace of mind.`

const FuneralPlans = (): React.ReactElement => (
  <>
    <JSONLD
      data={[
        FUNERAL_PLANS_JSONLD,
        getStructuredBreadCrumbs({
          itemListElements: [funeralPlanBreadCrumb],
        }),
      ]}
    />
    <DefaultLayout
      title={pageTitle}
      description={pageDescription}
      product={PRODUCTS.FUNERAL_PLAN}
      mobileHeaderColor={COLOR.WHITE}
      headerColor={COLOR.ACCENT.PRIMARY_60}
      headerStyle="funeralPlanSticky"
      email={FUNERAL_PLAN_EMAIL}
      telephoneNumber={FUNERAL_PLAN_TELEPHONE_NUMBER}
      footerShowFuneralPlansLegalInformation
      usePhoneNumberTracking={false}
    >
      <Content />
    </DefaultLayout>
  </>
)

export default FuneralPlans
